/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { ArrowInCircleRightIcon, DownloadIcon } from '@vlabs/icons';
import LoadingPage from '@vlabs/shared/components/loading-page/LoadingPage';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { useApiRequest } from '@vlabs/shared/hooks';
import { calcSimilarityPercent } from '@vlabs/shared/utils';
import validate from '@vlabs/shared/validators';
import {
  Page,
  Divider,
  Control,
  Grid,
  GridRow,
  GridCol,
  ButtonToggleGroup,
  ButtonToggle,
  Panel,
  Table,
} from '@vlabs/uikit';
import FileSaver from 'file-saver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ProgressCell from './progress-cell/ProgressCell';
import st from './VerifierTestPage.module.sass';

export const SUPPORTED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

const searchTypes = {
  byFaceId: 'face_ids',
  byEventId: 'event_ids',
  byExternalId: 'external_ids',
  byAttributeId: 'attribute_ids',
};

const VerifierTestPage = () => {
  const { t } = useTranslation();
  const [results, setResults] = useState();

  const { verifierId } = useParams();
  const { state: { data: verifierData }, isLoading } = useApiRequest({
    apiRequest: apiContainer.lunaClient.verifiers.get,
    parameters: verifierId,
  });

  const onSubmit = async (params) => {
    const response = await apiContainer.lunaClient.verifiers.verify(verifierId, params);
    const isVerifyResultExist = response.data.some(({ status }) => status !== undefined);
    if (!isVerifyResultExist) {
      toast.warning(t('verifiers:api.предупреждение.нет результатов верификации'));
      return;
    }
    setResults(response);
  };

  const downloadResults = useCallback((verifierName) => {
    const blob = new Blob([JSON.stringify(results?.rawData, null, 2)], { type: 'application/json' });
    FileSaver.saveAs(blob, `${verifierName}.json`);
  }, [results?.rawData]);

  const [searchType, setSearchType] = useState(searchTypes.byFaceId);
  const {
    register, handleSubmit, watch, reset, formState: { errors },
  } = useForm();

  const inputTitle = useMemo(() => ({
    face_ids: t('verifiers:verifierTest.searchBy.faceId'),
    external_ids: t('verifiers:verifierTest.searchBy.externalId'),
    attribute_ids: t('verifiers:verifierTest.searchBy.attributeId'),
    event_ids: t('verifiers:verifierTest.searchBy.eventId'),
  }), [t]);

  const DownloadColumn = useCallback(() => (
    <div title={t('verifiers:verifierTest.downloadResultsIconText')}>
      <Control.RoundButton
        data-testid="resultCell"
        icon={<DownloadIcon />}
        kind="primary"
        onClick={() => downloadResults(verifierData)}
        variant="dimmed"
      />
    </div>
  ), [downloadResults, verifierData, t]);

  const LinkCell = useCallback(({ row }) => {
    if (!row.original.status) return null;

    const links = {
      face_ids: `/faces/${row.original.faceId}`,
      event_ids: `/events/${row.original.eventId}`,
      external_ids: `/externalId/${row.original.externalId}`,
    };
    if (!links[searchType]) return null;

    return (
      <Control.Link
        icon={<ArrowInCircleRightIcon />}
        to={links[searchType]}
      />
    );
  }, [searchType]);

  const onChangeSearchType = (type) => {
    setResults();
    setSearchType(type);
    reset({ [type]: '' });
  };

  const inputField = useCallback(({ name }) => {
    return (
      <Control.Input
        data-testid="searchInput"
        id={searchType}
        label={inputTitle[name]}
        {...register(name, {
          required: searchType === name ? validate.required() : null,
          validate: searchType !== 'external_ids' && validate.uuid(),
          maxLength: searchType === 'external_ids' && validate.maxLength(36),
        })}
        errors={errors}
      />
    );
  }, [errors, register, searchType, inputTitle]);

  useEffect(() => {
    const sub = watch((formData, { name }) => {
      if (name === 'file' && !formData.file[0]?.length) {
        setResults();
      }
      if (name === searchType && !formData[searchType].length) {
        setResults();
      }
    });

    return () => sub.unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, results?.data]);

  if (isLoading || !verifierData) return <LoadingPage />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        actions={(
          <Control.Button type="submit">
            {t('verifiers:verifierTest.verifyButton')}
          </Control.Button>
          )}
        title={t('verifiers:verifierTest.pageTitle', { description: verifierData.description })}
      >
        <Divider samll />

        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <div className={st.Subtitle}>
                {t('verifiers:verifierTest.toggleGroup.title')}
              </div>

              <ButtonToggleGroup
                className={st.ButtonToggle}
                itemClassName={st.ButtonToggle__Item}
                onChange={onChangeSearchType}
                testId="buttonGroup"
                value={searchType}
              >
                <ButtonToggle value="face_ids">
                  {t('verifiers:verifierTest.toggleGroup.button.faceId')}
                </ButtonToggle>
                <ButtonToggle value="external_ids">
                  {t('verifiers:verifierTest.toggleGroup.button.externalId')}
                </ButtonToggle>
                <ButtonToggle value="attribute_ids">
                  {t('verifiers:verifierTest.toggleGroup.button.attributeId')}
                </ButtonToggle>
                <ButtonToggle value="event_ids">
                  {t('verifiers:verifierTest.toggleGroup.button.eventId')}
                </ButtonToggle>
              </ButtonToggleGroup>

              {inputField({ name: searchType })}
              <Divider small />

              <Control.FileInput
                {...register('file', {
                  required: validate.required(),
                  validate: (file) => {
                    if (!SUPPORTED_IMAGE_TYPES.includes(file[0].type)) {
                      return t('валидация.неподходящий формат файла');
                    }
                    return true;
                  },
                })}
                accept="image/png,image/jpeg,image/jpg,image/bmp"
                errors={errors}
              />
            </GridCol>
          </GridRow>

        </Grid>
        {results?.data !== undefined && (
          <>
            <Divider small />
            <Panel>
              <Table
                columns={[
                  {
                    Header: t('verifiers:verifierTest.table.columns.photo'),
                    accessor: 'sampleUiUrl',
                    Cell: ({ value }) => <Sample className={st.Sample} image={value} />,
                  },
                  {
                    Header: t('verifiers:verifierTest.table.columns.similarity'),
                    accessor: 'similarity',
                    Cell: ({ value }) => (value ? calcSimilarityPercent(value) : null),
                  },
                  {
                    Header: t('verifiers:verifierTest.table.columns.status'),
                    accessor: 'status',
                    Cell: ({ value }) => ProgressCell(value),
                  },
                  {
                    Header: t('verifiers:verifierTest.table.columns.link'),
                    Cell: LinkCell,
                  },
                  {
                    Header: DownloadColumn,
                    width: 30,
                    minWidth: 0,
                    id: 'downloadLink',
                  },
                ]}
                data={results?.data}
              />
            </Panel>
          </>
        )}
      </Page>
    </form>
  );
};

export default VerifierTestPage;
