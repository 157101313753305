/* eslint-disable no-unused-expressions */
import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { estimateFaceAttributesBySampleId } from '@vlabs/shared/requests';
import i18next from 'i18next';
import { toast } from 'react-toastify';

// FIXME: требуется рефакторинг, или вынести в другое место или выкинуть
import { updateFacesList } from '@vlabs/pages/lists/store';

const initialState = {
  state: undefined,
  faceId: undefined,
  face: undefined,
  attributes: undefined,
  details: undefined,
  facesWithTheSameExternalId: {
    data: [],
    pageIndex: 0,
    pageSize: 3,
    count: undefined,
  },
  eventsWithTheSameFaceId: {
    data: [],
    pageIndex: 0,
    pageSize: 3,
  },
};

const store = createSlice({
  name: 'facePage',
  initialState,
  reducers: {
    setState(state, { payload }) {
      state.state = payload;
    },
    setFaceId(state, { payload }) {
      return {
        ...initialState,
        faceId: payload,
      };
    },
    setFace(state, { payload }) {
      state.face = payload;
    },
    setAttributes(state, { payload }) {
      state.attributes = payload;
    },
    setFacesWithTheSameExternalId(state, { payload: { filteredFaces } }) {
      state.facesWithTheSameExternalId.data = filteredFaces;
      state.facesWithTheSameExternalId.count = filteredFaces.length;
    },
    resetFacesWithTheSameExternalId(state) {
      state.facesWithTheSameExternalId.data = [];
      state.facesWithTheSameExternalId.pageIndex = 0;
      state.facesWithTheSameExternalId.pageSize = 3;
      state.facesWithTheSameExternalId.count = undefined;
    },
    setFacesPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.facesWithTheSameExternalId.pageIndex = pageIndex;
    },
    setEventsWithTheSameFaceId(state, { payload }) {
      state.eventsWithTheSameFaceId.data = payload;
      state.eventsWithTheSameFaceId.count = payload.length;
    },
    setEventsPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.eventsWithTheSameFaceId.pageIndex = pageIndex;
    },
    setFaceDetails(state, { payload }) {
      state.details = payload;
    },
  },
});

export default store.reducer;

export const {
  setState,
  setFace,
  setAttributes,
  setFacesWithTheSameExternalId,
  setEventsWithTheSameFaceId,
  resetFacesWithTheSameExternalId,
  setFacesPage,
  setEventsPage,
  setFaceDetails,
} = store.actions;

// FIXME УДАЛИТСЯ ПРИ РЕФАКТОРИНГЕ ЭТОГО РАЗДЕЛА
export const fetchFaceById = async (id) => Promise.all([
  apiContainer.lunaClient.faces.get(id),
  apiContainer.lunaClient.faces.attributes(id).get(),
]).then(([
  { data: faceData },
  { data: { attributes: attributesData } },
]) => ({ faceData, attributesData }));

export const fetchFacesWithTheSameExternalId = async (dispatch, getState) => {
  const {
    facePage: {
      face,
      facesWithTheSameExternalId: { pageIndex },
    },
  } = getState();

  if (face?.external_id) {
    const { data } = await apiContainer.lunaClient.faces.getAll({
      external_ids: face?.external_id,
      page: pageIndex + 1,
      page_size: 10,
    });

    const filteredFaces = data?.filter(({ face_id }) => face_id !== face?.face_id);
    dispatch(setFacesWithTheSameExternalId({ filteredFaces }));
  } else {
    dispatch(resetFacesWithTheSameExternalId());
  }
};

export const fetchEventsWithTheSameFaceId = async (dispatch, getState) => {
  const {
    facePage: {
      face,
      eventsWithTheSameFaceId: { pageIndex },
    },
  } = getState();

  const events = await apiContainer.lunaClient.events.getAll({
    top_similar_object_ids: face?.face_id,
    page: pageIndex + 1,
    page_size: 9,
  });

  dispatch(setEventsWithTheSameFaceId(events));
};

export const fetchFace = async (dispatch, getState) => {
  dispatch(setState('loading'));
  const state = getState();
  const { facePage: { faceId } } = state;

  if (faceId === undefined) {
    dispatch(setState('failed'));
    return;
  }

  let faceData;
  let attributesData;
  let sampleId;

  try {
    const data = await fetchFaceById(faceId);
    faceData = data.faceData;
    attributesData = data.attributesData;
    sampleId = attributesData?.face_descriptor_samples?.[0];

    dispatch(setFace(faceData));
    dispatch(setAttributes(attributesData));
  } catch (error) {
    if (error.error.response.data.error_code === 22002) {
      dispatch(setFace(undefined));
      dispatch(setState('failed'));
      return;
    }

    throw error;
  }

  try {
    dispatch(fetchFacesWithTheSameExternalId);
    dispatch(fetchEventsWithTheSameFaceId);
    const facePage = await estimateFaceAttributesBySampleId(sampleId);

    dispatch(setFaceDetails(facePage));
    dispatch(setState('loaded'));
  } catch (error) {
    dispatch(setState('failed'));
    throw error;
  }
};

export const setFaceId = (faceId) => (dispatch) => {
  dispatch(store.actions.setFaceId(faceId));
  dispatch(fetchFace);
};

export const updateFaceAttributes = ({ sample_id, face_id }) => async (dispatch) => {
  const { data: [{ attribute_id }] } = await apiContainer.lunaClient.extractor
    .extractAttributes([sample_id], { extract_basic_attributes: 1 });
  await apiContainer.lunaClient.faces.attributes(face_id).put({ attribute_id });
  await apiContainer.lunaClient.faces.patch(face_id, { avatar: `/6/samples/faces/${sample_id}` });
  dispatch(fetchFace);
  toast.success(`${i18next.t('Информация успешно обновлена')}.`);
};

export const updateLists = (faceId, lists) => async (dispatch, getState) => {
  const {
    facePage: { face },
  } = getState();

  const listsToAttach = lists?.filter((listId) => !face.lists.includes(listId));
  const listsToDetach = face?.lists.filter((listId) => !lists.includes(listId));
  await Promise.all([
    listsToAttach.map((listId) => updateFacesList(listId, {
      action: 'attach',
      face_ids: [faceId],
    })),
    listsToDetach.map((listId) => updateFacesList(listId, {
      action: 'detach',
      face_ids: [faceId],
    })),
  ]);
};

export const updateFace = ({ lists, ...params }) => async (dispatch, getState) => {
  const {
    facePage: { face },
  } = getState();
  const { face_id } = face;
  await apiContainer.lunaClient.faces.patch(face_id, params);
  await updateLists(face_id, lists)(dispatch, getState);

  // Positive update
  setFace({
    ...face,
    ...params,
    lists,
  });

  setTimeout(async () => {
    await fetchFace(dispatch, getState);
    toast.success(`${i18next.t('Информация успешно обновлена')}.`);
  }, 100);
};

export const deleteFace = async (faceIds) => {
  await apiContainer.lunaClient.faces.delete(faceIds);
  toast.success(i18next.t('faces:лицо успешно удалено'));
};

export const updateFacesPage = (params) => async (dispatch) => {
  dispatch(setFacesPage(params));
};

export const updateEventsPage = (params) => async (dispatch) => {
  dispatch(setEventsPage(params));
};
