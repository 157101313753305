import React, { useState, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { DETECTION_TYPES } from '@vlabs/shared/components/detection-selector/constants';
import detectOnImageRequest from '@vlabs/shared/components/detection-selector/detectOnImageRequest';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { imageTypes } from '@vlabs/shared/config';
import { selectMaxImageSize } from '@vlabs/shared/selectors/appSelectors';
import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge, Control, Margin, Divider, FaceSelector } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { searchByRawDescriptor } from '@vlabs/pages/search/store';
// FIXME: требуется рефакторинг, или вынести в другое место или выкинуть

import './VerifyForm.sass';

// FIXME: это не компонент формы, перепроверить и поменять название чтобы не путаться
export function VerifyForm({ photo, faceId }) {
  const { t } = useTranslation();
  const maxImageSize = useSelector(selectMaxImageSize);
  const [file, setFile] = useState(undefined);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [matchResultSimilarity, setMatchResultSimilarity] = useState();
  const [isEmpty, setIsEmpty] = useState(!photo);

  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (!file) return;
    async function run() {
      const imageDetections = await detectOnImageRequest(
        file,
        [DETECTION_TYPES.FACES],
        apiContainer.lunaClient.sdk.estimateFace,
        apiContainer.lunaClient.sdk.estimateBody,
      );
      setCandidates(imageDetections);
    }
    run();
  }, [file]);

  const onFileSelect = async (newFile) => {
    if (!newFile) return;

    setFile(newFile);
  };

  const onReset = () => {
    setFile(undefined);
    setCandidates([]);
    setSelectedCandidates([]);
    setIsVerified(false);
    setMatchResultSimilarity(undefined);
  };

  const onFaceReset = () => {
    setSelectedCandidates([]);
  };

  const onMatch = async () => {
    const {
      data: {
        attributes: personAttributes,
      },
    } = await apiContainer.lunaClient.faces.attributes(faceId).get(faceId);

    const matches = await searchByRawDescriptor({ faceId, personAttributes, selectedCandidates, candidates });
    setIsVerified(true);
    setMatchResultSimilarity(matches?.[0].matches?.[0].similarity);
  };

  const renderResultBlock = () => {
    if (!isVerified) {
      return (
        <>
          {!file && (
            <Control.FileDropzone
              data-testid="fileSelector"
              maxImageSize={maxImageSize}
              onChangeValue={onFileSelect}
              onlyImages
              supportedImageTypes={imageTypes}
            />
          )}

          {file && file.type === 'image' && (
            <div className="FacePhotoContainer">
              <FaceSelector
                faces={candidates.all}
                onReset={onFaceReset}
                onSelectFace={([facesId]) => facesId && setSelectedCandidates(facesId)}
                photo={file.binary}
                single
              />
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <img alt="фотография" className="VerifyFormFaceHolder" data-testid="image" src={file.binary || null} />
        <Badge
          className="FaceCardBadge"
          kind={calcBadgeType(matchResultSimilarity)}
          value={calcSimilarityPercent(matchResultSimilarity)}
        />
      </>
    );
  };

  return (
    <div className="VerifyForm">
      <h5>{t('externalID:modal.verification.title')}</h5>
      <Divider small />

      <div className="VerifyFormPhotoContainer">
        <div className="FacePhotoContainer">
          {photo && !isEmpty && (
            <img
              alt="фотография"
              className="VerifyFormFaceHolder"
              data-testid="image"
              onError={() => setIsEmpty(true)}
              src={photo || null}
            />
          )}
          {isEmpty && <EmptyImage className="EmptyFaceMessage" />}
        </div>
        <Margin right />
        <div className="FacePhotoContainer">{renderResultBlock()}</div>
      </div>
      <Divider />

      <div className="VerifyFormBottom">
        <Control.Button disabled={candidates.length === 0} onClick={onMatch} type="button">
          {t('externalID:action.verify')}
        </Control.Button>

        <Margin right />
        <Control.Button disabled={file === null || file === undefined} kind="negative" onClick={onReset}>
          {t('externalID:button.reset')}
        </Control.Button>
      </div>
    </div>
  );
}

VerifyForm.propTypes = {
  photo: PropTypes.string.isRequired,
  faceId: PropTypes.string.isRequired,
};
