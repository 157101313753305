import React, { useState, useCallback } from 'react';

import { ArrowInCircleRightIcon } from '@vlabs/icons';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import st from './EventSample.module.sass';

export const EventSample = ({
  event,
  eventType,
  onEventClick,
}) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(false);
  // FIXME: нейминг продумать нормальный а не uiSampleURL
  const detectionSampleURL = eventType === 'face' ? event?.uiFaceDetection?.uiSampleURL : event?.uiBodyDetection?.uiSampleURL;

  // FIXME: не для всех событий делаем запрос на events.isExist поэтому пока кривая проверка
  const isEventExist = event?.isExist !== false || event?.isExist === undefined;

  const onClick = useCallback((e) => {
    if (e.code === 'Enter' || e.code === undefined) {
      e.stopPropagation();
      onEventClick(e);
    }
  }, [onEventClick]);

  return (
    <>
      {detectionSampleURL && isEventExist && !isEmpty && (
        <div
          className={st.Root}
          data-testid="eventSample"
        >
          <img
            alt="Sample"
            className={cn({
              [st.Sample]: true,
              [st.Sample_body]: eventType === 'body',
            })}
            key={`${detectionSampleURL}_sample`}
            onError={() => setIsEmpty(true)}
            src={detectionSampleURL}
          />

          {onEventClick && (
            <ButtonScrimGroup className={st.Sample__Link}>
              <ButtonScrimItem
                icon={<ArrowInCircleRightIcon />}
                onClick={onClick}
              />
            </ButtonScrimGroup>
          )}
        </div>
      )}

      {((detectionSampleURL && !isEventExist) || isEmpty) && (
        <div
          className={st.Root}
          data-testid="eventSample"
        >
          <EmptyImage
            className={cn([{
              [st.Sample]: true,
              [st.Sample_nophoto]: true,
              [st.Sample_body]: eventType === 'body',
            }])}
            dataTip={t('events:проверьте настройки сценария')}
            messageText={isEventExist ? t('events:top match cell.no image') : t('events:event deleted')}
            onImageClick={onEventClick ? onClick : undefined}
          />
        </div>
      )}
    </>
  );
};
