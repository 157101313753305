import React, { useContext } from 'react';

import { EventFiltersForm, FaceFiltersForm } from '@vlabs/pages/search/forms';
import { defaultFilterValues } from '@vlabs/pages/search/helpers';
import { SearchContext } from '@vlabs/pages/search/search-context';
import { Control, ButtonToggleGroup, ButtonToggle } from '@vlabs/uikit';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './Filters.module.sass';

export const Filters = () => {
  const { t } = useTranslation();
  const {
    setFilters,
    resetMatchResults,
    setCandidateOrigin,
    candidateOrigin,
    matcherType,
  } = useContext(SearchContext);

  const formMethods = useForm({ defaultValues: defaultFilterValues });
  const { reset, handleSubmit } = formMethods;

  const onResetFilters = () => {
    resetMatchResults();
    reset(defaultFilterValues);
  };

  const onChangeCandidateOrigin = (value) => {
    resetMatchResults();
    reset(defaultFilterValues);
    setCandidateOrigin(value);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(setFilters)}>
        <div className={st.Root}>
          <div>
            <ButtonToggleGroup
              className={st.ButtonToggle}
              itemClassName={st.ButtonToggle__Item}
              onChange={onChangeCandidateOrigin}
              testId="filtersButtonGroup"
              value={candidateOrigin}
            >
              <ButtonToggle value="events">
                {t('search:искать события')}
              </ButtonToggle>
              <ButtonToggle
                disabled={matcherType && matcherType === 'body'}
                value="faces"
              >
                {t('search:искать лица')}
              </ButtonToggle>
            </ButtonToggleGroup>
            {candidateOrigin === 'events' && <EventFiltersForm />}
            {candidateOrigin === 'faces' && <FaceFiltersForm />}
          </div>

          <div className="StickyBottomContainer">
            <div className={st.ButtonPanel}>
              <Control.Button
                data-testid="button.onFilter"
                type="submit"
              >
                {t('button.filter')}
              </Control.Button>

              <Control.Button
                data-testid="button.onReset"
                kind="attention"
                onClick={onResetFilters}
                type="button"
              >
                {t('button.reset')}
              </Control.Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
