import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
// FIXME: требуется рефакторинг, или вынести в другое место или выкинуть
import { estimateFaceAttributesBySampleId, getEventCardData } from '@vlabs/shared/requests';

import { fetchFaceById } from '@vlabs/pages/face-details-page/store';

const initialState = {
  state: undefined,
  data: undefined,
  pageIndex: 0,
  pageSize: 10,
};

const store = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setState(state, { payload }) {
      state.state = payload;
    },
    setEvent(state, { payload }) {
      state.data = payload;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    reset: () => initialState,
  },
});

export default store.reducer;

export const { setState, setEvent, setPage } = store.actions;

export const updatePage = (params) => (dispatch) => {
  dispatch(setPage(params));
};

const saveEventData = async ({ event_id, similarity }) => {
  const { data } = await apiContainer.lunaClient.events.get(event_id);
  const sampleId = data?.uiFaceDetection?.sample_id;

  const topMatchAttributes = await estimateFaceAttributesBySampleId(sampleId);
  return {
    similarity,
    detection: topMatchAttributes,
    ...data,
  };
};

const saveFaceData = async ({ face_id, similarity }) => {
  const {
    faceData,
    attributesData: { basic_attributes, ...attributes },
  } = await fetchFaceById(face_id);

  const topMatchAttributes = await estimateFaceAttributesBySampleId(attributes?.face_descriptor_samples?.[0]);

  return {
    ...faceData,
    ...attributes,
    ...basic_attributes,
    similarity,
    detection: topMatchAttributes,
  };
};

export const fetchEvent = (eventId) => async (dispatch) => {
  dispatch(store.actions.reset());
  dispatch(setState('loading'));
  let response;

  try {
    response = await apiContainer.lunaClient.events.get(eventId);
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch(setEvent(undefined));
      dispatch(setState('failed'));
      return;
    }
    throw error;
  }

  try {
    const {
      location,
      uiFaceDetection,
      top_match,
      match_result,
      ...rest
    } = response.data;

    let attributes;
    let filteredLocation = {};

    if (uiFaceDetection?.sample_id) {
      const sampleId = uiFaceDetection?.sample_id;
      attributes = await estimateFaceAttributesBySampleId(sampleId);
    }

    Object.entries(location).forEach(([key, value]) => {
      if (!value) return;
      filteredLocation[key] = value;
    });

    if (Object.keys(filteredLocation).length === 0) filteredLocation = undefined;

    let topMatch = top_match;

    if (top_match?.event_id) {
      const isExist = await apiContainer.lunaClient.events.isExist(top_match.event_id);
      topMatch = { ...top_match, type: 'event', isDeleted: !isExist };

      if (isExist) {
        const eventData = await saveEventData(top_match);
        topMatch = { ...topMatch, ...eventData };
      }
    } else if (top_match?.face_id) {
      const isExist = await apiContainer.lunaClient.faces.isExist(top_match.face_id);
      topMatch = { ...top_match, type: 'face', isDeleted: !isExist };
      if (isExist) {
        const faceData = await saveFaceData(top_match);
        topMatch = { ...topMatch, ...faceData };
      }
    }

    const matchList = await getEventCardData(match_result);

    dispatch(
      setEvent({
        location: filteredLocation,
        detection: attributes,
        uiFaceDetection,
        topMatch,
        matchList,
        ...rest,
      }),
    );

    dispatch(setState('loaded'));
  } catch (error) {
    dispatch(setState('failed'));
    throw error;
  }
};
