/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CreateListWidget, DeleteFacesFromListWidget, EditListWidget } from '@vlabs/pages/lists/widgets/index';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { ExporterTaskWidget } from '@vlabs/shared/widgets/tasks';
import { PackageImportWidget } from '@vlabs/shared/widgets/tasks/package-import/PackageImportWidget';
import { TableCells, useTableHook, Table, openConfirmPopup, Panel, Page, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { DeleteAndDetachCell, RefreshFaceCountCell } from './components/index';
import { fetchLists as fetchListsAction } from './store';

function ListsListPage({
  can,
  updateListsStore,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [faceCount, setFaceCount] = useState({});

  const fetchLists = useCallback(({ page = 0, pageSize, ...params }) => apiContainer.lunaClient.lists
    .getAll({ page: page + 1, page_size: pageSize, ...params })
    .then(({ data: { lists: list } }) => ({ data: list })), []);

  const tableProps = useTableHook(fetchLists, { paginationType: 'offsetBased' });

  const onDeleteLists = useCallback(({ data, withFace }) => {
    const text = withFace ? 'удалить с лицами' : 'удалить без лиц';
    const listIds = Array.isArray(data) ? data.map((list) => list.list_id) : [data.list_id];
    const message = t(`lists:подтверждение.${text}.текст`, {
      count: listIds.length,
      item: Array.isArray(data) ? data[0].user_data : data.user_data,
    });

    const onConfirm = async () => {
      await apiContainer.lunaClient.lists.deleteMany(listIds, { with_faces: withFace ? 1 : 0 });
      toast.success(t('lists:подтверждение.удаление.успех'));
      tableProps.onFetch();
      updateListsStore();
    };

    openConfirmPopup({
      title: t(`lists:подтверждение.${text}.заголовок`),
      message,
      type: 'delete',
      onConfirm,
    });
  }, [t, tableProps, updateListsStore]);

  const deleteWithFace = useCallback((data) => onDeleteLists({ data, withFace: true }), [onDeleteLists]);
  const deleteWithoutFace = useCallback((data) => onDeleteLists({ data, withFace: false }), [onDeleteLists]);

  const onSelectListHandler = useCallback(({
    list_id,
  }) => history.push(generatePath(routes.lists.read, { listId: list_id })), [history]);

  const extraButtons = (context) => {
    const {
      selectedFlatRows,
    } = context;
    const selectedRows = selectedFlatRows.map(({ original }) => original);
    return (
      <>
        <CreateListWidget afterSubmit={tableProps.onFetch} />
        <ExporterTaskWidget objectType="faces" />
        <PackageImportWidget />
        <Control.Button
          data-testid="table.deleteListWithoutFaces"
          disabled={selectedRows.length === 0}
          kind="negative"
          onClick={() => deleteWithoutFace(selectedRows)}
        >
          {t('lists:таблица.кнопки.удалить без лиц')}
        </Control.Button>
      </>
    );
  };

  const columns = useMemo(() => (
    [
      actionColumnProps({
        id: 'selection',
        type: 'action',
        Header: TableCells.SelectAllRowsCell,
        Cell: TableCells.SelectRowCell,
      }),
      {
        Header: <TableCells.TableHeaderCell
          label={t('lists:таблица.columns.name.header.text')}
          title={t('lists:таблица.columns.name.header.title')}
        />,
        accessor: 'user_data',
      },
      {
        Header: <TableCells.TableHeaderCell
          label={t('lists:таблица.columns.creation date.header.text')}
          title={t('lists:таблица.columns.creation date.header.title')}
        />,
        accessor: 'create_time',
        Cell: TableCells.DateCell,
      },
      {
        Header: <TableCells.TableHeaderCell
          label={t('lists:таблица.columns.edited date.header.text')}
          title={t('lists:таблица.columns.edited date.header.title')}
        />,
        accessor: 'last_update_time',
        Cell: TableCells.DateCell,
      },
      actionColumnProps({ id: 'action-refresh', Cell: ({ row }) => <RefreshFaceCountCell listId={row.original.list_id} setFaceCount={setFaceCount} /> }),
      {
        Header: <TableCells.TableHeaderCell
          label={t('lists:таблица.columns.face count.header.text')}
          title={t('lists:таблица.columns.face count.header.title')}
        />,
        accessor: 'count',
        width: 80,
        align: 'center',
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      actionColumnProps({ id: 'edit', Cell: ({ row }) => <EditListWidget afterSubmit={tableProps.onFetch} hasPermission={can(permissions.list.modification)} listData={row.original} /> }),
      actionColumnProps({ id: 'delete lists without faces', Cell: ({ row }) => DeleteAndDetachCell({ onClick: deleteWithoutFace, row, buttonProps: { hasPermission: can(permissions.list.deletion) } }) }),
      actionColumnProps({ id: 'delete all faces in list', Cell: ({ row }) => <DeleteFacesFromListWidget hasPermission={can(permissions.task.creation)} listId={row.original.list_id} /> }),
      actionColumnProps({ id: 'delete lists with faces', Cell: ({ row }) => TableCells.DeleteCell({ row, buttonProps: { hasPermission: can(permissions.list.deletion) } }) }),
    ]
  ), [can, deleteWithoutFace, t, tableProps.onFetch]);

  const tableData = useMemo(() => {
    return {
      ...tableProps,
      data: tableProps?.data?.map((list) => ({
        ...list,
        count: faceCount[list.list_id],
      })),
    };
  }, [tableProps, faceCount]);

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Panel className="Global__Table_fullScreen">
        <Table
          {...tableData}
          columns={columns}
          disableSortBy={false}
          extraButtons={extraButtons}
          onActions={{
            onClickRow: { handler: onSelectListHandler, can: can(permissions.list.view) },
            onDeleteRow: { label: t('lists:таблица.кнопки.удалить с лицами'), handler: deleteWithFace, can: can(permissions.list.deletion) },
          }}
          rowProps={(row) => ({ 'data-list-id': row.original.list_id })}
        />
      </Panel>
    </Page>
  );
}

ListsListPage.propTypes = {
  can: PropTypes.func.isRequired,
  updateListsStore: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateListsStore: () => fetchListsAction(dispatch),
});

export default connect(
  (state) => ({
    can: viewerCan(state),
  }), mapDispatchToProps,
)(ListsListPage);
