import { combineReducers } from '@reduxjs/toolkit';
import lunaStreamsReducers from '@vlabs/pages/sources/luna-streams/features/reducer';
import vlAccessReducers from '@vlabs/pages/sources/vl-access/reducers';
import vaReporterReducers from '@vlabs/pages/va-reporter/reducer';

import accountsSlice from '@vlabs/pages/accounts/store';
import accountSlice from '@vlabs/pages/auth/store';
import departmentsSlice from '@vlabs/pages/departments/store';
import eventDetailsSlice from '@vlabs/pages/events/event-page/store';
import eventsSlice from '@vlabs/pages/events/events/store';
import latestEventsSlice from '@vlabs/pages/events/latest-events/store';
import facePageSlice from '@vlabs/pages/face-details-page/store';
import handlerSlice from '@vlabs/pages/handlers/store';
import listsSlice from '@vlabs/pages/lists/store';
import notificationsSlice from '@vlabs/pages/notifications/store';
import matcherSearchSlice from '@vlabs/pages/search/store';
import thermoSlice from '@vlabs/pages/thermo/store';
import timeTrackingSlice from '@vlabs/pages/time-tracking-tasks/store';

import appSlice from './appSlice';

export default combineReducers({
  app: appSlice,
  facePage: facePageSlice,
  account: accountSlice,
  accounts: accountsSlice,
  lists: listsSlice,
  events: eventsSlice,
  event: eventDetailsSlice,
  latestEvents: latestEventsSlice,
  departments: departmentsSlice,
  matcherSearch: matcherSearchSlice,
  handlers: handlerSlice,
  notifications: notificationsSlice,
  reports: timeTrackingSlice,
  thermo: thermoSlice,
  ...vaReporterReducers,
  ...vlAccessReducers,
  ...lunaStreamsReducers,
});
