import React, { useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { FaceQualitySubForm, FiltersSubForm, DeepfakeSubForm, LivenessSubForm } from '@vlabs/pages/handlers/forms/index';
import LoadingPage from '@vlabs/shared/components/loading-page/LoadingPage';
import { permissions } from '@vlabs/shared/config';
import { useApiRequest } from '@vlabs/shared/hooks';
import { Page, Divider, Control, Grid, GridRow, GridCol, Fold } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { CommonSubForm, EstimationsSubForm, StoragePolicySubForm } from './forms/index';

const VerifierFormPage = ({
  can,
}) => {
  const { t } = useTranslation();

  const { verifierId } = useParams();
  const { state: { data: verifier }, isLoading } = useApiRequest({
    apiRequest: apiContainer.lunaClient.verifiers.get,
    parameters: verifierId,
  });

  const formMethods = useForm({ defaultValues: verifier });
  const { reset, handleSubmit } = formMethods;
  useEffect(() => { reset(verifier); }, [verifier, reset]);

  if (isLoading || !verifier) return <LoadingPage />;

  const onAction = (
    <Control.Button
      hasPermission={can(permissions.verifier.modification)}
      type="submit"
      variant="outlined"
    >
      {t('форма.submit')}
    </Control.Button>
  );

  const onSubmit = async (values) => {
    await apiContainer.lunaClient.verifiers.update(verifierId, values);
    toast.success(`${t('verifiers:api.успешно обновлен')}.`);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page
          actions={onAction}
          title={t('verifiers:editForm.form title')}
        >
          <Divider />

          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.common')}
                >
                  <CommonSubForm />
                </Fold>
                <Divider />

                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.defined attributes')}
                >
                  <EstimationsSubForm />
                </Fold>
                <Divider />

                <LivenessSubForm />
                <Divider />

                <DeepfakeSubForm />
                <Divider />

                <FaceQualitySubForm />
              </GridCol>

              <GridCol cols={6}>
                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.save parameters')}
                >
                  <StoragePolicySubForm />
                </Fold>
                <Divider />

                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.filters')}
                >
                  <FiltersSubForm />
                </Fold>
              </GridCol>
            </GridRow>
          </Grid>
        </Page>
      </form>
    </FormProvider>
  );
};

VerifierFormPage.propTypes = {
  can: PropTypes.func.isRequired,
};

export default connect((state) => ({
  can: viewerCan(state),
}))(VerifierFormPage);
