/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { DownloadIcon } from '@vlabs/icons';
import {
  DetectionCard,
  AdditionalCell,
  CommonEventInfo,
  TopMatchCard,
  LocationInfo,
} from '@vlabs/pages/events/event-page/components';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import { downloadJson } from '@vlabs/shared/utils';
import { Divider, Grid, GridCol, GridRow, Page, Panel, Table, TableCells, openConfirmPopup } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectEventData, selectMatchResult } from '@vlabs/pages/events/event-page/selectors';
import { fetchEvent as fetchEventQuery, updatePage } from '@vlabs/pages/events/event-page/store';

import st from './EventPage.module.sass';

const EventSampleCell = ({ value }) => (
  <Sample
    className={cn(st.MatchTable__Image, st[`MatchTable__Image_${value.type}`])}
    data-testid="eventTableSample"
    {...value}
  />
);

const EventGoToCell = ({ value }) => {
  const { t } = useTranslation();
  if (!value) return t('events:match table.deleted');
  return <GoToCell value={value} />;
};

function EventPage({
  event,
  matchResult,
  setPageIndex,
  fetchEvent,
}) {
  const { t } = useTranslation();
  const { eventId: paramsEventId } = useParams();

  useEffect(() => {
    fetchEvent(paramsEventId);
  }, [fetchEvent, paramsEventId]);

  if (event === undefined) return null;

  const onDownloadHandler = ({ event_id, meta }) => {
    const onConfirm = () => {
      downloadJson(`${event_id}_meta`, meta);
    };

    openConfirmPopup({
      icon: <DownloadIcon />,
      title: t('events:подтверждение.загрузить мета.заголовок'),
      message: t('events:подтверждение.загрузить мета.текст'),
      onConfirm,
      confirmButtonProps: {
        kind: 'success',
      },
      confirmLabel: t('events:подтверждение.submit', { context: 'скачать' }),
    });
  };

  return (
    // FIXME: проверить работу гридов,
    // при padding 32px не появляется горизонтальный скролл, а при 10px появляется
    <Page className={st.Page} title={t('events:page.title')}>
      <Divider small />
      <Panel className={st.Panel}>
        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <CommonEventInfo event={event} onDownloadHandler={onDownloadHandler} />
            </GridCol>
            <GridCol cols={6}>
              <LocationInfo {...event} />
            </GridCol>
          </GridRow>
        </Grid>
      </Panel>

      <Divider small />

      <Grid>
        <GridRow>
          <GridCol cols={6}>
            <DetectionCard event={event} />
          </GridCol>

          <GridCol cols={6}>
            {event?.topMatch && <TopMatchCard event={event?.topMatch} />}
          </GridCol>
        </GridRow>
      </Grid>

      {matchResult?.length > 0 && (
        <>
          <Divider small />
          <Panel>
            <h6 className={st.MatchTable__Title}>{t('events:match table.title')}</h6>
            <Table
              columns={[
                {
                  Header: t('events:match table.event photo'),
                  accessor: (value) => ({
                    image: value?.avatar,
                    similarity: value?.similarity,
                    isDeleted: value?.isDeleted,
                    type: value?.type,
                  }),
                  Cell: EventSampleCell,
                  width: 50,
                },
                {
                  Header: t('events:match table.type'),
                  accessor: (value) => (value?.type === 'face' ? t('face') : t('event')),
                  width: 50,
                },
                { Header: t('date created'), accessor: 'create_time', Cell: TableCells.DateCell, width: 80 },
                { Header: t('label'), accessor: 'label', width: 80 },
                {
                  Header: t('additional information'),
                  id: 1,
                  Cell: AdditionalCell,
                },
                {
                  id: 2,
                  accessor: ({ event_id: eventId, face_id: faceId, isDeleted }) => {
                    if (isDeleted) return undefined;
                    return eventId ? `/events/${eventId}` : `/faces/${faceId}`;
                  },
                  Cell: EventGoToCell,
                  width: 30,
                },
              ]}
              data={matchResult}
              pageIndex={0}
              pageSize={10}
              pageSizeOptions={[10]}
              paginationType="offsetBased"
              setPageIndex={setPageIndex}
            />
          </Panel>
        </>
      )}
    </Page>
  );
}

EventPage.propTypes = {
  event: PropTypes.shape({}),
  matchResult: PropTypes.arrayOf(PropTypes.any),
  setPageIndex: PropTypes.func,
  fetchEvent: PropTypes.func.isRequired,
};

EventPage.defaultProps = {
  matchResult: [],
  event: undefined,
  setPageIndex: undefined,
};

export default connect(
  (state) => ({
    event: selectEventData(state),
    matchResult: selectMatchResult(state),
  }),
  (dispatch) => ({
    setPageIndex: (pageIndex) => dispatch(updatePage({ pageIndex })),
    fetchEvent: (id) => dispatch(fetchEventQuery(id)),
  }),
)(EventPage);
